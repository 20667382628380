<template>
  <v-container fluid style="background: #fff !important; margin-top: -60px">
    <div v-if="isloading" class="text-align-center">Generating Waybill...</div>
    <div v-if="!isloading">
      <div class="layout-set">
        <!--head-->
        <header class="border-botm">
          <div class="row">
            <div class="col-md-3 logo text-left">
              <img src="../../assets/v_logo_big.png" style="width: 140px" />
            </div>
            <div class="col-md-6 text-center">
              <h3 class="orange-text">TRUKKIN MIDDLE EAST FZ LLC</h3>
            </div>
            <div class="col-md-3 text-right print-link">
              <button type="button" onclick="window.print()" class="btn-orng">
                Print
              </button>
            </div>
          </div>
        </header>
        <!--head-->
        <div class="on-bord pt-2 pb-1">
          <h4>ON-BOARDING FORM</h4>
        </div>
        <!--driver-details-->
        <div class="driver-details">
          <h3 style="font-weight: bold; color: #333333">Driver Details</h3>
          <div class="row">
            <div class="col-12 col-md-6">
              <p>
                <span>Driver ID</span>
                <label>{{ driverDetails.driverId }}</label>
              </p>
            </div>
            <div class="col-12 col-md-6">
              <p>
                <span>Name of Driver</span>
                <label
                  >{{ driverDetails.firstName }}
                  {{ driverDetails.lastName }}</label
                >
              </p>
            </div>
            <div class="col-12 col-md-6">
              <p>
                <span>Mobile No.</span>
                <label
                  >{{ driverDetails.countryCode }}-{{
                    driverDetails.phoneNo
                  }}</label
                >
              </p>
            </div>
            <div class="col-12 col-md-12">
              <p>
                <span>Address</span>
                <label>{{ driverDetails.address }}</label>
              </p>
              <p v-if="driverDetails.workingCountry != 'PAK'">
                <span v-if="driverDetails.workingCountry != 'PAK'"
                  >IQAMA No. / Visa No. / Emirates ID No.</span
                >
                <label v-if="driverDetails.workingCountry != 'PAK'">{{
                  driverDetails.emiratesID || ""
                }}</label>
              </p>
              <p v-if="driverDetails.workingCountry != 'PAK'">
                <span v-if="driverDetails.workingCountry != 'PAK'"
                  >IQAMA No. / Visa No. / Emirates ID Expiry</span
                >
                <label
                  v-if="
                    driverDetails.emiratesIDExpiryDate &&
                    driverDetails.workingCountry != 'PAK'
                  "
                  >{{
                    getTime3(driverDetails.emiratesIDExpiryDate) || ""
                  }}</label
                >
              </p>
            </div>
            <div
              class="col-12 col-md-6"
              v-if="driverDetails.workingCountry != 'PAK'"
            >
              <p v-if="driverDetails.workingCountry != 'PAK'">
                <span v-if="driverDetails.workingCountry != 'PAK'"
                  >Passport Number</span
                >
                <label v-if="driverDetails.workingCountry != 'PAK'">{{
                  driverDetails.passPortNumber || ""
                }}</label>
              </p>
            </div>
            <div
              class="col-12 col-md-6"
              v-if="driverDetails.workingCountry != 'PAK'"
            >
              <p v-if="driverDetails.workingCountry != 'PAK'">
                <span v-if="driverDetails.workingCountry != 'PAK'"
                  >Passport Expiry</span
                >
                <label
                  v-if="
                    driverDetails.passportExpiryDate &&
                    driverDetails.workingCountry != 'PAK'
                  "
                  >{{ getTime3(driverDetails.passportExpiryDate) || "" }}</label
                >
              </p>
            </div>
            <div class="col-12 col-md-6">
              <p>
                <span>Driving License</span>
                <label>{{ driverDetails.licenseNumber || "" }}</label>
              </p>
            </div>
            <div class="col-12 col-md-6">
              <p>
                <span>Driving License Expiry</span>
                <label v-if="driverDetails.licenseExpiryDate">{{
                  getTime3(driverDetails.licenseExpiryDate) || ""
                }}</label>
              </p>
            </div>
            <div class="col-12 col-md-6">
              <p>
                <span>Vehicle Registration No.</span>
                <label>{{ driverDetails.vehicleReg || "" }}</label>
              </p>
            </div>
            <div class="col-12 col-md-6">
              <p>
                <span>Vehicle Registration No. Expiry</span>
                <label v-if="driverDetails.regExpiryDate">{{
                  getTime3(driverDetails.regExpiryDate) || ""
                }}</label>
              </p>
            </div>

            <div class="col-12 col-md-6">
              <p>
                <span>Nationality</span>
                <label>{{ driverDetails.nationality }}</label>
              </p>
            </div>
          </div>
        </div>
        <!--driver-details-->

        <!--company-details-->
        <div class="driver-details">
          <h3 style="font-weight: bold; color: #333333">Company Details</h3>
          <div class="row">
            <div class="col-12 col-md-12">
              <p>
                <span>Company Name (Visa Sponsor)</span>
                <label>{{ driverDetails.sponsorName || "" }}</label>
              </p>
            </div>
            <div class="col-12 col-md-12">
              <p>
                <span>Company Address (Visa Sponsor)</span>
              </p>
            </div>
            <div class="col-12 col-md-6">
              <p>
                <span>Company Contact no.</span>
                <label>{{ driverDetails.sponsorPhone || "" }}</label>
              </p>
            </div>
            <div class="col-12 col-md-6">
              <p>
                <span>Company Email ID</span>
              </p>
            </div>
            <div class="col-12 col-md-12">
              <p>
                <span>Do you have haulier’s liability insurance? Yes/No</span>
              </p>
            </div>
            <p class="col-12 bold-p">
              ( If yes, please provide us a copy of the same. )
            </p>
          </div>
        </div>
        <!--company-details-->
        <!--Declaration :-->
        <div class="driver-details">
          <div class="row">
            <div class="col-12 col-md-12 border-none">
              <p style="width: 102px; float: left">
                <span>Declaration :</span>
              </p>
              <p
                style="
                  width: calc(100% - 120px);
                  float: left;
                  text-decoration: underline;
                "
                class="bold-p"
              >
                I confirm that the above contents are true and accurate to my
                belief. In case of any mishap, company will not be responsible.
              </p>
            </div>
            <div class="col-12 col-md-6">
              <p>
                <span>Driver Signature</span>
              </p>
            </div>
            <div class="col-12 col-md-6">
              <p>
                <span>Date</span>
              </p>
            </div>
            <div class="col-12 col-md-12 border-none">
              <p style="width: 175px; float: left">
                <span>Documents Required :</span>
              </p>
              <p
                style="
                  width: calc(100% - 180px);
                  float: left;
                  line-height: 20px;
                "
                class="bold-p"
              >
                Trade Licence Copy, Representative’s IQAMA ID / NATIONAL ID
                Copy, Vehicle registration / Mulika Copy, Vehicle Authorisation
                Letter/ NOC/ Tafweed Copy, Driver’s Driving License Copy,
                Vehicle Insurance Copy.
              </p>
            </div>
          </div>
        </div>
        <!--Declaration-->
      </div>

      <!--footer-->
      <div class="footer">
        <div class="row" style="margin-top: 5px">
          <div class="col-md-4">
            <label style="color: #808080; font-size: 14px; width: 100%"
              >Reach us</label
            >
            <br />
            <p
              style="
                color: #6c6c6c;
                font-weight: 500;
                font-size: 12px;
                display: inline-flex;
              "
            >
              <span class="icon-footer">
                <img src="../../assets/phone-call.svg" />
              </span>
              <span>
                <label
                  style="display: flex; align-items: center; margin-bottom: 0"
                >
                  <img
                    src="../../assets/flag-1.svg"
                    style="width: 27px; margin: 0px 12px"
                  />2800-04273
                </label>

                <br />
                <label
                  style="display: flex; align-items: center; margin-bottom: 0"
                >
                  <img
                    src="../../assets/flag-2.svg"
                    style="width: 27px; margin: 0px 12px"
                  />9200-04273
                </label>
              </span>
            </p>
            <br />
            <p
              style="
                color: #6c6c6c;
                font-weight: 500;
                font-size: 12px;
                display: inline-flex;
                align-items: center;
              "
            >
              <span class="icon-footer">
                <img src="../../assets/email.svg" />
              </span>
              <span>
                <label style="margin: 0; margin-left: 14px"
                  >operation@trukkin.com</label
                >
              </span>
            </p>
            <br />
            <p
              style="
                color: #6c6c6c;
                font-weight: 500;
                font-size: 12px;
                display: inline-flex;
                align-items: center;
              "
            >
              <span class="icon-footer">
                <img src="../../assets/web.svg" />
              </span>
              <span>
                <label style="margin: 0; margin-left: 14px"
                  >www.trukkin.com</label
                >
              </span>
            </p>
          </div>
          <div class="col-md-4">
            <label style="color: #808080; font-size: 14px; width: 100%"
              >We are serving</label
            >
            <br />
            <ul class="serve">
              <li>UAE</li>
              <br />
              <li>KSA</li>
              <br />
              <li>Kuvait</li>
              <br />
              <li>Bahrain</li>
            </ul>
            <ul class="serve">
              <li>Oman</li>
              <br />
              <li>Lebanon</li>
              <br />
              <li>Iraq</li>
              <br />
              <li>Yemen</li>
            </ul>
            <ul class="serve">
              <li>Jordan</li>
              <br />
              <li>Egypt</li>
              <br />
              <li>Syria</li>
              <br />
              <li>Pakistan</li>
            </ul>
          </div>
          <div class="col-md-4" style="padding-left: 80px">
            <label style="color: #808080; font-size: 14px; width: 100%"
              >Scan the QR code</label
            >
            <br />
            <img src="../../assets/qrcodeimage.png" width="100px" />
            <p style="color: #808080; width: 220px">
              Available in
              <span
                style="float: right; margin-right: 40px"
                class="icon-footer"
              >
                <img src="../../assets/apple.svg" />
              </span>
              <span
                class="icon-footer"
                style="float: right; margin-right: 20px"
              >
                <img src="../../assets/android.svg" />
              </span>
            </p>
          </div>
          <p class="time">{{ timestamp() }}</p>
        </div>
      </div>
      <!--footer-->
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import { driverInfoForm } from "@/constants/api-urls.js";
import WaybillDetailContent from "../Waybill/WaybillDetailContent";
import { StorageKeys } from "../../constants/constants";
import { EventBus } from "../../event-bus.js";
export default {
  components: {
    WaybillDetailContent,
  },
  created() {
    EventBus.$emit("in-waybill");
    this.$eventBus.$emit("hide-navbar");
    const { id } = this.$route.params;
    if (!id) {
      alert("Invalid Page URL Parameters. Please contact administrator");
    }
    this.fetch(id);
  },
  data() {
    return {
      driverDetails: {},
      isloading: false,
      generatedAt: null,
    };
  },
  methods: {
    getTime3(date) {
      return moment.utc(date).format("DD/MM/YYYY");
    },
    setCountry(type) {
      this.country = type;
    },
    fetch(id) {
      this.isloading = true;
      let url = driverInfoForm;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        driverId: id.toString(),
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.isloading = false;
          const { data = null } = response.data;
          if (data) {
            this.driverDetails = data;

            // var date = new Date(this.waybill.generatedAt);
            // this.generatedAt = date.toLocaleString();
            return;
          } else {
            alert("Failed to build");
            // window.close();
          }
        },
        (error) => {
          this.isloading = false;
          const message = this.$eventBus.parse(error);
          alert(message);
          // window.close();
        }
      );
    },
    print() {
      window.print();
    },
    timestamp() {
      return moment().format("hh:mm a");
    },
  },
};
</script>

<style scoped lang="scss">
#waybillPage {
  top: 10px;
  left: 10%;
  height: 100%;
  width: 100%;
  font-size: 12px;
  background: #fff;
  font-family: Arial, Helvetica, sans-serif;

  .miscbox {
    border: 1px solid #00000021 !important;
    padding: 0px 0px;
  }
}

@page {
  margin: 0cm;
}
.bill-A4 {
  width: 100%;
  margin: 0 auto;
  background: #fff;
  border: 1px solid #e9e9e9;
  max-width: 80%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.layout-set {
  padding: 20px;
  padding-bottom: 0;
}
p {
  margin: 0;
}
.orange-text {
  color: #f37924;
  font-weight: bold;
}
.btn-orng {
  background: #fea600;
  border: 0;
  color: #fff;
  padding: 6px 31px;
  text-transform: uppercase;
}
.border-botm {
  border-bottom: 4px solid #fea600;
  padding-bottom: 12px;
}
.on-bord h4 {
  color: #666666;
  position: relative;
  padding-left: 10px;
}
.on-bord h4::before {
  content: "";
  position: absolute;
  width: 3px;
  background: #f37924;
  height: 100%;
  border-radius: 50px;
  left: 0;
}
.driver-details p {
  width: 100%;
  border-bottom: 1px solid #bcbcbc;
  line-height: 0.8em;
  margin: 8px 0 8px;
  color: #848484;
  display: inline-block;
}

.driver-details p span {
  background: #fff;
  padding: 0 8px 0px 0px;
  color: #848484;
}
.driver-details p label {
  margin-bottom: 0px !important;
}
.driver-details {
  padding: 10px;
}
.bold-p {
  color: #666666;
  font-weight: 600;
  border: 0 !important;
}
.border-none p {
  border: 0 !important;
}
.footer {
  padding: 20px;
  background: #f9f9f9;
}
.icon-footer {
  float: left;
  background: #f0f0f0;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
}
// .h3,
// h3 {
//   font-size: 1.75rem;
// }
// .h4,
// h4 {
//   font-size: 1.5rem;
// }
.icon-footer img {
  width: 17px;
}
.serve {
  color: #6c6c6c;
  font-weight: 500;
  word-spacing: 10px;
  font-size: 14px;
  line-height: 18px;
  float: left;
  padding: 0;
  padding-left: 16px;
  width: 33%;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.col-md-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.col-md-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.col-md-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.col-md-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.footer label {
  margin-bottom: 15px;
  display: inline-block;
}
.time {
  width: 100%;
  text-align: right;
  border-top: 1px solid #f2f2f2;
  margin: 0px 20px;
  margin-bottom: 10px;
  padding-top: 5px;
  margin-bottom: 0;
  color: #808080;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
@media print {
  .print-link {
    display: none;
  }
}
</style>
